import React from "react"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import style from "./main.module.css"
import arrow from "../images/arrow.svg"
import { Greeting } from "../components/greeting/Greeting"
import scrollTo from "gatsby-plugin-smoothscroll"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="БИТ" />
      <div className={style.head}>
        <Greeting />
        <a onClick={() => scrollTo("#aim")} className={style.arrow}>
          <img src={arrow} alt={"arrow"} />
        </a>
      </div>
    </Layout>
  )
}

export default IndexPage