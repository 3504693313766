import React from "react"
import style from "./greeting.module.css"
import globalStyle from "../global.module.css"
import classnames from "classnames"
import peoples from "../../images/people.png"
import logo from "../../images/bit-logo.svg"

export const Greeting = () => {
  return (
    <div className={classnames(style.bit, globalStyle.globalContainer)}>
      <div className={classnames(style.greetingContainer)}>
        <div className={classnames(style.logoContainer)}>
          <div className={style.logobit}>
            <img className={style.logoImg} src={logo} alt={"logo"} />
            <h1 className={style.future}>
              Будущее ИТ
            </h1>
          </div>
          <img className={style.containerMax} src={peoples} alt="peoples" />
        </div>
          <h2 className={style.fond}>
            Фонд развития образования Приднестровья
          </h2>
        <img className={style.container} src={peoples} alt="peoples" />
      </div>
    </div>
  )
}
